import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import data from "../../data/index.json";
import { AppWrap, MotionWrap } from "../../wrapper";
// import "./About.css";
import { useTranslation } from "react-i18next";


const About = () => {
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div>
        <br />
        <h3 className="head-text">{t("what we are Good at")}:</h3>
        <br />
        <h3 className="head-text">{t("Our services")}</h3>
        <section className="skills--section">
          <div className="skills--section--container">
            {data?.skills?.map((item, index) => (
              <div key={index} className="skills--section--card">
                <div className="skills--section--img">
                  <img
                    src={item.src}
                    alt="Product Chain"
                    style={{
                      height: 150,
                      width: 150,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </div>
                <div className="skills--section--card--content">
                  <h3 className="skills--section--title">{t(item.title)}</h3>
                  <p className="skills--section--description">
                   {t(item.description)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>

      {/* <div className="app__profiles">
        {data?.skills?.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "tween" }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img
              src={t(about.src)}
              alt={t(about.title)}
              // style={{
              //   height: 150,
              //   width: 150,
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
            />
            <h2 className="bold-text" style={{ marginTop: 20 }}>
              {t(about.title)}
            </h2>
            <p className="p-text" style={{ marginTop: 10 }}>
              {t(about.description)}
            </p>
          </motion.div>
        ))}
      </div> */}
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "service",
  "app__whitebg"
);

import React from "react";
import "./Portfolio.scss";
import "./Blog.scss";
import Card from "./Card";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import { useNavigate } from "react-router-dom";
import data from "../../data/index.json"; // Adjust the path as needed
import { t } from "i18next";

const Blog = () => {
  const navigate = useNavigate();

  // Extract BlogApi data from the imported JSON
  const { BlogApi } = data;

  const handleCardClick = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <>
      <h2 className="head-text">{t("The hyphen Blog")}</h2>

      <div className="app__profiles">
        {BlogApi.map((blog) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3, type: "tween" }}
            className="app__profile-item"
            key={blog.id}
          >
            <Card blog={blog} onClick={() => handleCardClick(blog.id)} />
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(MotionWrap(Blog, "app__about"), "blog", "app__whitebg");

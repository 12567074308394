import { useEffect } from "react";
import Animation from "./Animation";
import { Link } from "react-scroll";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Header.scss";
import { useTranslation } from "react-i18next";

function HeroSection() {
  const handleClick = (e) => {
    e.preventDefault();
    window.location.replace("#contact");
  };
  const { t } = useTranslation();
  const handleClick1 = (e) => {
    e.preventDefault();
    window.open("/Cosebu", "_blank");
  };

  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content-wrapper">
        <div className="hero--section--content">
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">Hyphen Tech</span>{" "}
          </h1>
          <p className="section--title">
            {t("Empowering Success Through Technology")}
          </p>
          <br />
          <p className="hero--section-description">
            {t("Transforming lives and scaling businesses with technology")}
          </p>
        </div>
        <div className="button-container">
          <button className="btn btn-github" onClick={(e) => handleClick(e)}>
            {t("Get In Touch")}
          </button>
          {/* <button className="btn btn-github" onClick={handleClick1}>
            Visit our ongoing project
          </button> */}
        </div>
      </div>
      <div className="hero--section--img">
        <div style={{ marginBottom: "330px" }}></div>
        <Animation />
      </div>
    </section>
  );
}
export default AppWrap(HeroSection, "home");

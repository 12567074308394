import React from "react";

import {
  About,
  Footer,
  Header,
  Skills,
  Testimonial,
  Work,
  Blog,
  Showcase,
  Team,
} from "./container";
import { Navbar } from "./components";
import "./App.css";

const Home = () => (
  <div className="app">
    <Navbar />
    <Header />
    <About />
    <Work />
    <Showcase />
    <Skills />
    <Team />
    <Blog />
    <Testimonial />
    <Footer />
   
    
  </div>
);

export default Home;

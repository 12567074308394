import React from "react";
import animation from "./animation.scss";
import {
  faCss3,
  faGitAlt,
  faJsSquare,
  faNode,
  faReact,
  faHtml5,
} from "@fortawesome/free-brands-svg-icons";
import { images } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import python from "./python.svg";
import tensorflow from "./tensorflow.svg";
import android from "./android.svg";
import typescript from "./typescript.svg";

function Animation() {
  return (
    <div className="stage-cube-cont">
      <div className="cubespinner">
        <div className="face1">
          <img src={tensorflow} alt="" style={{ height: 150, width: 150 }} />
        </div>
        <div className="face2">
          <img
            src={images.hyphenimage}
            alt=""
            style={{ height: 150, width: 150 }}
          />
        </div>
        <div className="face3">
          <img src={python} alt="" style={{ height: 150, width: 150 }} />
        </div>
        <div className="face4">
          <img src={typescript} alt="" style={{ height: 150, width: 150 }} />
        </div>
        <div className="face5">
          <FontAwesomeIcon
            icon={faJsSquare}
            color="#EFD81D"
            style={{ height: 150, width: 150 }}
          />
        </div>
        <div className="face6">
          <img src={android} alt="" style={{ height: 150, width: 150 }} />
        </div>
      </div>
    </div>
  );
}

export default Animation;

import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./showcase.scss";
import data from "../../data/index.json"; // Adjust the path as needed
import { useTranslation } from "react-i18next";

function Experience() {
  const [selected, setSelected] = useState(0);
  const showcase = data.showcase;
  const { t } = useTranslation();

  useEffect(() => {
    const underline = document.querySelector(".underline");
    const selectedItem = document.querySelector(".exp-slider-item-selected");

    if (underline && selectedItem) {
      const itemHeight = selectedItem.offsetHeight;
      const itemTop = selectedItem.offsetTop;

      underline.style.height = `${itemHeight}px`;
      underline.style.top = `${itemTop}px`;
    }
  }, [selected]);

  return (
    <motion.div
      className="experience"
      id="experience"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      variants={{
        visible: { opacity: 1, y: -20 },
        hidden: { opacity: 0, y: 20 },
      }}
    >
      <div className="title">
        <h2>{t("The Showcase Study")}</h2>
      </div>
      <div className="container">
        <ul className="exp-slider" role="tablist" aria-label="Experience tabs">
          <div className="underline"></div>
          {showcase.map((experience, index) => (
            <li
              className={`exp-slider-item ${
                index === selected ? "exp-slider-item-selected" : ""
              }`}
              onClick={() => setSelected(index)}
              key={experience.name}
              role="tab"
              aria-selected={index === selected ? "true" : "false"}
              tabIndex={index === selected ? "0" : "-1"}
            >
              <span>{experience.name}</span>
            </li>
          ))}
        </ul>
        <div className="exp-details">
          <div className="exp-details-position">
            <h3>
              <span>{t(showcase[selected].role)}</span>
              <span className="exp-details-position-company">
                 @ 
                <a
                  href={showcase[selected].url}
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {showcase[selected].name}
                </a>
              </span>
            </h3>
            <p className="exp-details-range">
              {showcase[selected].start} - {showcase[selected].end}
            </p>
            <ul className="exp-details-list">
              {showcase[selected].shortDescription.map((description, index) => (
                <li key={index} className="exp-details-list-item">
                  {t(description)}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Experience;

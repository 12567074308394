import React from "react";
import ReactDOM from "react-dom";
import "./i18n"; // make sure to import your i18n configuration
import "./index.scss";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

import React from "react";
import "./style.css";
import FooterCosebu from "./FooterCosebu";
import img1 from "./assets/img1.webp";
import img2 from "./assets/img2.png";
import img3 from "./assets/img3.webp";
import v from "./assets/vision.jpg";
import el from "./assets/elie.jpg";
import d from "./assets/d.png";
import e from "./assets/elo.png";
import j from "./assets/jar.jpg";

import svg from "./assets/instructor-vactor-1.svg";
import ContactMe from "./ContactMe";
import NavbarCosebu from "./NavbarCosebu";

const Cosebu = () => {
  const handleBecomeSellerClick = () => {
    window.open(
      "https://forms.gle/Jzc6MzNPU6BMG7iy8",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      <NavbarCosebu />

      <section className="heroo" id="homeCosebu">
        <div className="containr">
          <figure className="heroo-banner">
            <img
              src={img1}
              alt="A young lady sits, holding a pen and a notebook."
            />
          </figure>

          <div className="heroo-content">
            <h1 className="h1 heroo-title">
              COSEBU: "Connecting Sellers and Buyers” Seamlessly
            </h1>

            <p className="section-text">
              COSEBU (Connect sellers and Buyers) is a revolutionary e-commerce
              platform designed to bridge the gap between sellers and buyers.
              Our innovative tools and features streamline transactions, enhance
              communication, and boost sales for businesses of all sizes in
              Burundi.
              <br />
              <i>
                Do you wish to become a seller, register to the waiting list of
                sellers and you can also register as investor and get a
                downloadable file for more details in coming weeks.
              </i>
            </p>
            <br />
            <div className="cont">
              <button
                className="btnn btnn-primary"
                onClick={handleBecomeSellerClick}
              >
                Become Seller
              </button>

              <a
                href="https://forms.gle/6PVqqvUR5tWDUhwr8"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btnn btnn-primary">Become Investor</button>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="about" id="aboutCosebu">
        <div className="containr">
          <figure className="about-banner">
            <img src={v} alt="Eduland students" className="about-img" />
          </figure>

          <div className="about-content">
            <h2 className="h2 about-title">
              Vision of Hyphen Tech about Cosebu
            </h2>

            <p className="section-text">
              At Hyphen Tech, our mission is to empower local entrepreneurs,
              businesses, and individuals to thrive in their daily activities
              through the transformative power of modern technology. With
              Cosebu, we aim to empower businesses to achieve unprecedented
              growth and customer satisfaction by providing cutting-edge
              solutions that connect sellers and buyers more efficiently for
              trade.
            </p>
          </div>
        </div>
      </section>

      {/* Problem Section */}
      <section className="about" id="problemCosebu">
        <div className="containr">
          <figure className="about-banner">
            <img src={img2} alt="Eduland students" className="about-img" />
          </figure>

          <div className="about-content">
            <h2 className="h2 about-title">
              How the Idea of COSEBU came out? First, some background
            </h2>

            <p className="section-text">
              In 2022, Elie Bubuya, the founder of Cosebu, and the team were
              conducting interviews with white and blue-collar workers in
              Bujumbura, Burundi. The aim was to understand their daily
              struggles in purchasing goods. The data collected revealed
              significant challenges: Time Constraints (60% of workers faced
              issues finding time to go to the market), Limited Product
              Availability, Unreliable Delivery Infrastructure, Inefficient
              Product Comparison, Limited variety of products available. On the
              other hand, Local businesses wanted to sell but could not connect
              with buyers due to these issues. Notably, 95% of workers had
              smartphones and internet access. When we proposed a platform to
              connect sellers and buyers, the response was overwhelmingly
              positive as 60% of workers were interested and 40% of them willing
              to be early adopters of our product when it is launched.
            </p>
          </div>
        </div>
      </section>

      {/* Solution Section */}
      <section className="about" id="solutionCosebu">
        <div className="containr">
          <figure className="about-banner">
            <img src={img3} alt="Eduland students" className="about-img" />
          </figure>

          <div className="about-content">
            <h2 className="h2 about-title">Our solution</h2>

            <p className="section-text">
              Cosebu (Connect Sellers and Buyers) is more than an e-commerce
              platform; it is a catalyst for change. We envision a future where
              Burundian and African businesses thrive on national and
              international stages. Cosebu allows entrepreneurs to create and
              manage their shops, delivering better services and products to
              customers. Cosebu offers an intuitive user interface, ensuring a
              seamless experience for everyone. We provide secure payments
              through trusted providers, a diverse product selection, fast and
              reliable delivery, and real-time order tracking for transparency.
              Our dedicated customer service team will always be available to
              support both sellers and buyers.
            </p>
          </div>
        </div>
      </section>

      {/* Prototype Section */}
      <section className="departments" id="prototypeCosebu">
        <div className="containr">
          <h2 className="h2 departments-title">The Prototype</h2>

          <ul className="departments-list">
            <li>
              <div className="departments-card">
                <a href="#" className="card-banner">
                  <figure>
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/vBK00s7Bbjc?si=NcGQwcmpOpBpMmur"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </figure>
                </a>

                <a href="#">
                  <h3 className="h3 card-title">Cosebu Web App</h3>
                </a>

                <p className="card-text">
                  This is our Cosebu Web application designed to be very
                  friendly with our customers.
                </p>

                <a href="#" className="card-link">
                  <span>Learn More</span>

                  <ion-icon name="arrow-forward"></ion-icon>
                </a>
              </div>
            </li>
            <li>
              {/* <div className="departments-card">
                <a href="#" className="card-banner">
                  <figure>
                    <video autoPlay loop muted controls src={webvideo}></video>
                  </figure>
                </a>

                <a href="#">
                  <h3 className="h3 card-title">Cosebu Website</h3>
                </a>

                <p className="card-text">
                  This is our Cosebu Website designed to be very friendly with
                  our customers.
                </p>

                <a href="#" className="card-link">
                  <span>Learn More</span>

                  <ion-icon name="arrow-forward"></ion-icon>
                </a>
              </div> */}
            </li>
          </ul>
        </div>
      </section>

      <section className="skills--section" id="MyServices"></section>

      {/* Team Section */}
      <section className="instructor" id="teamCosebu">
        <div className="containr">
          <img src={svg} className="vector-line" />

          <div className="title-wrapper">
            <h2 className="h2 instructor-title">Introduce Our Team</h2>
          </div>

          <ul className="instructor-list">
            <li>
              <div className="instructor-card">
                <figure className="card-banner">
                  <img src={el} alt="Elie BUBUYA" />
                </figure>

                <a href="#">
                  <h3 className="card-title">Elie BUBUYA</h3>
                </a>

                <p className="card-subtitle">Founder & CEO</p>

                <ul className="card-social-list">
                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-google"></ion-icon>
                    </a>
                  </li>

                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-twitter"></ion-icon>
                    </a>
                  </li>

                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-instagram"></ion-icon>
                    </a>
                  </li>

                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-linkedin"></ion-icon>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              <div className="instructor-card">
                <figure className="card-banner">
                  <img src={d} alt="Dieudonné ABANABIMANA" />
                </figure>

                <a href="#">
                  <h3 className="card-title">Dieudonné ABANABIMANA</h3>
                </a>

                <p className="card-subtitle">COO & Co-founder</p>

                <ul className="card-social-list">
                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-google"></ion-icon>
                    </a>
                  </li>

                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-twitter"></ion-icon>
                    </a>
                  </li>

                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-instagram"></ion-icon>
                    </a>
                  </li>

                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-linkedin"></ion-icon>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              <div className="instructor-card">
                <figure className="card-banner">
                  <img src={j} alt="Jarry Hugue NIGABA" />
                </figure>

                <a href="#">
                  <h3 className="card-title">Jarry Hugue NIGABA</h3>
                </a>

                <p className="card-subtitle">Product Manager</p>

                <ul className="card-social-list">
                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-google"></ion-icon>
                    </a>
                  </li>

                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-twitter"></ion-icon>
                    </a>
                  </li>

                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-instagram"></ion-icon>
                    </a>
                  </li>

                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-linkedin"></ion-icon>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              <div className="instructor-card">
                <figure className="card-banner">
                  <img src={e} alt="Eloge ABIKUNNDA" />
                </figure>

                <a href="#">
                  <h3 className="card-title">Eloge ABIKUNNDA</h3>
                </a>

                <p className="card-subtitle">Product Manager</p>

                <ul className="card-social-list">
                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-google"></ion-icon>
                    </a>
                  </li>

                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-twitter"></ion-icon>
                    </a>
                  </li>

                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-instagram"></ion-icon>
                    </a>
                  </li>

                  <li>
                    <a href="#" className="card-social-link">
                      <ion-icon name="logo-linkedin"></ion-icon>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta">
        <div className="containr">
          <div className="title-wrapper">
            <h2 className="h2 cta-title">
              <span>Become a seller and invest in COSEBU</span>
            </h2>
            <div className="conti">
              <button
                className="btnn btnn-primary"
                onClick={handleBecomeSellerClick}
              >
                Become Seller
              </button>
              <a
                href="https://forms.gle/6PVqqvUR5tWDUhwr8"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btnn btnn-primary">Become Investor</button>
              </a>
            </div>
          </div>

          <div className="cta-banner"></div>
        </div>
      </section>

      {/* Footer */}
      <ContactMe />
      <FooterCosebu />
    </>
  );
};

export default Cosebu;

import { images } from "../../constants";

const BlogApi = [
  {
    id: 1,
    image: images.diego,
    date: "2 April, 2024",
    title_one: "Championing Community Impact through Technology",
    desc_one:
      "At Hyphen Tech, our commitment extends beyond business growth and innovation. We are dedicated to harnessing the power of technology to create meaningful and lasting impacts in our communities. We are proud to highlight a significant milestone facilitated by one of our own. Dieudonné Abanabimana, our esteemed former COO, co-founder, and co-CTO, played a crucial role in the live broadcast of the 75th Jubilee of the Diocese of the Catholic Church of Ngozi. This momentous event celebrated the blessing of new priests as they embarked on their spiritual journeys. Supported by our talented interns, Dieudonné ensured that this important occasion reached a wider audience, allowing many to partake in the celebration virtually.This event is a testament to Hyphen Tech's mission to leverage technology for the greater good. We believe in the transformative power of tech solutions to enrich lives and strengthen communities.",
    title_two: "Hyphen Tech's Impact at the Africa Youth Leadership Forum",
    desc_two:
      "On March 1, 2024, Hyphen Tech played a pivotal role in supporting the Africa Youth Leadership Forum at Ngozi University. Our team expertly managed and live-streamed the conference organized by the university's clubs, ensuring its success through dedication and technical proficiency. Following the conference, our founders, Elie Bubuya and Dieudonné Abanabimana, unveiled an innovative platform developed by Hyphen Tech. This platform, specifically designed for the students of Ngozi University, serves as a blog that facilitates access to and sharing of opportunities, scholarships, and discussions on leadership, entrepreneurship, and more.",
    title_three:
      "Hyphen Tech Recognized Among Top Innovations in Burundi by IEEE",
    desc_three:
      "On March 27th, Hyphen Tech had the incredible opportunity to participate in a prestigious technological competition organized by IEEE at the University of Lake Tanganyika. We proudly showcased two innovative projects that represent our dedication to pushing the boundaries of technology and creativity. 💡We are thrilled to announce that we have been selected among the top 5 best innovations in Burundi by the esteemed organization IEEE and have received certification. This recognition is a testament to our commitment to excellence and innovation. Stay tuned for more updates as we continue to innovate and strive for excellence! Thank you for your unwavering support.",
  },
  {
    id: 2,
    image:images.speech,
    date: "27 July, 2024",
    title_one: "Hyphen Tech CEO Elie Bubuya Advances AI Knowledge in Benin",
    desc_one:
      "Our CEO and co-CTO, Elie Bubuya, has embarked on a groundbreaking journey to Benin. 🚀 Over the course of a month, Elie immersed himself in the fundamentals of machine learning, artificial intelligence, and embedded systems with robotics. Elie was selected as one of only 120 individuals from across Africa to participate in the prestigious École d'Été sur Intelligence Artificielle (EEIA), organized in partnership with Foundation Vallet and Benin Excellence. This event is the largest educational gathering on Artificial Intelligence in Africa, with an astounding 9,013 applications! 🌍We are incredibly proud of Elie for passing this rigorous selection process and earning a place at such a distinguished event. His dedication and passion for advancing technology in East Africa are truly inspiring. Stay tuned for more updates as Elie explores these cutting-edge fields. We’ll be sharing more about his involvement in AI projects across East Africa soon!",
    title_two: "",
    desc_two: "",
    title_three: "",
    desc_three: " ",
  },
  {
    id: 3,
    image: images.educ,
    date: "01 August, 2024",
    title_one: "Hyphen Tech Education: Empowering Future Innovators",
    desc_one:
      "At Hyphen Tech, we believe that investing in technology and education is essential for building a prosperous society. As part of our commitment to this vision, Hyphen Tech Education, a non-profit initiative, aims to provide unfortunate children with the basics of informatics. Every year in August, during the vacation period, we partner with Ngozi University and the American Corner Ngozi to deliver this transformative program. Our mission is to share knowledge and inspire young minds, nurturing the future innovators of our society. By equipping children with foundational informatics skills, we aim to bridge the technology gap and create opportunities for them to thrive in an increasingly digital world. We recognize that technology remains largely unexploited in our country and across Africa. Through Hyphen Tech Education, we aspire to reach a larger audience in the future, extending our impact and fostering a culture of technological advancement. Join us in our journey to empower the next generation and build a brighter future through technology and education.",
    title_two: "",
    desc_two: "",
    title_three: "",
    desc_three: "",
  },
];
export default BlogApi;

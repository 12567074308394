import React, { useState } from "react";

import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Footer.scss";
import { useTranslation } from "react-i18next";
import fb from "./facebook-color-svgrepo-com.svg";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <h2 className="head-text">{t("Connect with Us")}</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={images.email} alt="email" />

          <a href="mailto: info@hyphentech.tech" className="p-text">
            <bold>info@hyphentech.tech</bold>
          </a>
        </div>
        <div className="app__footer-card ">
          <img src={images.email} alt="email" />

          <a href="mailto: hyphentechinfo@gmail.com" className="p-text">
            <bold>hyphentechinfo@gmail.com</bold>
          </a>
        </div>

        <div className="app__footer-card ">
          <img src={images.whatsapp} alt="email" />

          <a
            href="https://wa.me/25762153272" // Replace with your WhatsApp number in international format
            target="_blank"
            rel="noopener noreferrer"
            className="p-text"
          >
            +257 62 15 32 72
          </a>
        </div>
        <div className="app__footer-card ">
          <img src={images.whatsapp} alt="email" />

          <a
            href="https://wa.me/25762674277" // Replace with your WhatsApp number in international format
            target="_blank"
            rel="noopener noreferrer"
            className="p-text"
          >
            +257 62 67 42 77
          </a>
        </div>
        <div className="app__footer-card ">
          <img src={fb} alt="email" />

          <a
            href="https://www.facebook.com/profile.php?id=100064389290696"
            className="navbar--content"
            target="_blank"
            rel="noreferrer"
          >
            Follow us on social media 👍
          </a>
        </div>
      </div>
      <br />

      <div className="copyright">
        <p className="p-text">
          {" "}
          &copy; 2023-{new Date().getFullYear()} Hyphen Tech. All rights
          reserved
        </p>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__whitebg"
);

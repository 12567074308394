import whatsappLogo from "./assets/whatsapp-symbol-logo-svgrepo-com.svg";
import emaillogo from "./assets/gmail-svgrepo-com.svg";

export default function ContactMe() {
  return (
    <section id="Contact" className="contact--section">
      <div className="contact--header">
        <p className="sub--title">Get In Touch</p>
        <h2>Contact Us</h2>
        <p className="text-lg">
          Feel free to contact us via email or phone for any inquiries.
        </p>
      </div>
      <div className="contact--info--container">
        <div className="contact--info-box">
          <div className="contact--info">
            <p className="text-md">Email:</p>
            <p className="text-lg">
              <a
                href="mailto:info@hyphentech.tech" // Replace with your WhatsApp number in international format
                className="whatsapp-link btn btn-outline-primary"
              >
                <img src={emaillogo} alt="WhatsApp" className="whatsapp-logo" />
                info@hyphentech.tech
              </a>
            </p>
            <p className="text-lg">
              <a
                href="mailto:hyphentechinfo@gmail.com" // Replace with your WhatsApp number in international format
                className="whatsapp-link btn btn-outline-primary"
              >
                <img src={emaillogo} alt="WhatsApp" className="whatsapp-logo" />
                hyphentechinfo@gmail.com
              </a>
            </p>
          </div>
        </div>
        <div className="contact--info-box">
          <div className="contact--info">
            <p className="text-md">Phone:</p>
            <p className="text-lg">
              <a
                href="https://wa.me/25762153272" // Replace with your WhatsApp number in international format
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-link btn btn-outline-primary"
              >
                <img
                  src={whatsappLogo}
                  alt="WhatsApp"
                  className="whatsapp-logo"
                />
                +257 62 15 32 72
              </a>
            </p>
            <p className="text-lg">
              <a
                href="https://wa.me/25762153272" // Replace with your WhatsApp number in international format
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-link btn btn-outline-primary"
              >
                <img
                  src={whatsappLogo}
                  alt="WhatsApp"
                  className="whatsapp-logo"
                />
                +257 62 67 42 77
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

// App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import { Blog, Testimonial } from "./container";
import BlogDetails from "./container/Blog/BlogDetails";
import Cosebu from "./container/Cosebu/Cosebu";
import Loader from "./components/Loader";
import "./i18n"; // make sure to import your i18n configuration

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1900);
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} setIsLoading={setIsLoading} />
      {!isLoading && (
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="#blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogDetails />} />
            <Route path="/cosebu" element={<Cosebu />} />
          </Routes>
        </Router>
      )}
    </>
  );
};

export default App;

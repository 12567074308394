import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from './resources';

// Function to get the default language
const getDefaultLanguage = () => {
  const deviceLanguage = navigator.language.split('-')[0]; // Get the language code
  const supportedLanguages = ['en', 'fr', 'es'];
  return supportedLanguages.includes(deviceLanguage) ? deviceLanguage : 'en';
};

i18n.use(initReactI18next).init({
  resources,
  lng: getDefaultLanguage(), // Use the detected language
  fallbackLng: 'en', // Fallback to English if translation is missing
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
import React from "react";
import { motion } from "framer-motion";
import ReactTooltip from "react-tooltip";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Skills.scss";
import { images } from "../../constants";
import { useTranslation } from "react-i18next";
import data from "../../data/index.json"; // Adjust the path as needed

const Skills = () => {
  const { t } = useTranslation();

  // Extract experiences1 data from the imported JSON
  const { experiences } = data;

  const skills = [
    {
      name: "React",
      bgColor: "#1b7c96",
      icon: images.react,
    },
    {
      name: "Node.js",
      bgColor: "#3C873A",
      icon: images.node,
    },
    {
      name: "JavaScript",
      bgColor: "#F7DF1E",
      icon: images.javascript,
    },
    {
      name: "Tensorflow",
      bgColor: "#E34F26",
      icon: images.tensorflow,
    },
    {
      name: "Typescript",
      bgColor: "#1572B6",
      icon: images.typescript,
    },
    {
      name: "Python",
      bgColor: "#3776AB",
      icon: images.python,
    },
    {
      name: "MongoDB",
      bgColor: "#47A248",
      icon: images.mongodb,
    },
    {
      name: "Git",
      bgColor: "#8a2613",
      icon: images.git,
    },
  ];

  return (
    <>
      <h2 className="head-text">{t("Skills & Experiences")}</h2>

      <div className="app__skills-container" id="#skills">
        <motion.div className="app__skills-list">
          {skills.map((skill) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={skill.name}
            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={skill.icon} alt={skill.name} />
              </div>
              <p className="p-text">{t(skill.name)}</p>
            </motion.div>
          ))}
        </motion.div>
        <div className="app__skills-exp">
          {experiences.map((experience) => (
            <motion.div className="app__skills-exp-item" key={experience.year}>
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {experience.works.map((work) => (
                  <>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                      key={work.name}
                    >
                      <h4 className="bold-text">{t(work.name)}</h4>
                      <p className="p-text">{t(work.company)}</p>
                    </motion.div>
                    <ReactTooltip
                      id={work.name}
                      effect="solid"
                      arrowColor="#fff"
                      className="skills-tooltip"
                    >
                      {t(work.desc)}
                    </ReactTooltip>
                  </>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, "app__skills"),
  "skills",
  "app__whitebg"
);

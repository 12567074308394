import React from "react";
import "./Blog.scss";
import { useTranslation } from "react-i18next";

const Card = ({ blog, onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="app__profile-item" onClick={onClick}>
      <div className="img">
        <img src={t(blog.image)} alt={t(blog.title_one)} />
      </div>
      <div className="content">
        <div className="category">
          <span>{blog.date}</span>
        </div>
        <div className="title">
          <h2>{t(blog.title_one)}</h2>
          <a href="#popup" className="arrow">
            <i className="fas fa-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Card;

import React from "react";
import "./team.scss";
import { AppWrap, MotionWrap } from "../../wrapper";
import data from "../../data/index.json"; // Adjust the path as needed
import { useTranslation } from "react-i18next";

const Team = () => {
  const { team } = data;
  const { t } = useTranslation();

  return (
    <div className="team-section">
      <div className="team-header">
        <p className="header-subtitle">{t("Teamwork is the Key")}</p>
        <h2 className="header-title">{t("The Team")}</h2>
      </div>
      <div className="team-members">
        {team && team.length > 0 ? (
          team.map((member, index) => (
            <div className="team-member-wrapper" key={index}>
              <div className="member-image">
                <img src={member.img} alt={member.name} />
              </div>
              <div className="member-info">
                <h3>{t(member.name)}</h3>
                <p className="position">{t(member.position)}</p>
                <p className="expertise">{t(member.expertise)}</p>
                <p className="details">{t(member.details)}</p>
              </div>
            </div>
          ))
        ) : (
          <p>No team members found</p>
        )}
      </div>
    </div>
  );
};

export default AppWrap(MotionWrap(Team, "app__team"), "team", "app__whitebg");

import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./loader.scss";

function Loader({ isLoading, setIsLoading }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500); // Adjusted duration to allow text animation to complete
    return () => clearTimeout(timer);
  }, [setIsLoading]);

  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          className="loader"
          exit={{ opacity: 0 }}
          key="motiondivleave"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
          }}
        >
          <motion.svg
            id="logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <title>Logo</title>
            <g>
              <g id="H" transform="translate(35.000000, 35.000000)">
                <motion.path
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 1,
                    ease: "easeInOut",
                    delay: 1,
                  }}
                  exit={{
                    scale: 2,
                  }}
                  fill="currentColor"
                  d="M6.085938,3.90625 L6.085938,25.140625 L10.429688,25.140625 L10.429688,17.246094 L15.269531,17.246094 L15.269531,25.140625 L19.613281,25.140625 L19.613281,3.90625 L15.269531,3.90625 L15.269531,11.800781 L10.429688,11.800781 L10.429688,3.90625 Z"
                />
              </g>
              <motion.path
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                  duration: 1,
                  ease: "easeInOut",
                }}
                exit={{
                  scale: 2,
                }}
                stroke="currentColor"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
              />
            </g>
          </motion.svg>
          <motion.div
            className="loader-text"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
              delay: 1.5,
            }}
          >
            Powered by Hyphen Tech
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Loader;

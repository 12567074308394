import React, { useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Testimonial.scss";
import data from "../../data/index.json"; // Adjust the path as needed
import { useTranslation } from "react-i18next";

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation();

  const { testimonials } = data;

  const brands = [
    {
      _id: "1",
      name: "Bismos",
      imgUrl: "./img/ILLUSTRATION LOGO BISMOS .png",
    },
    {
      _id: "2",
      name: "American spaces",
      imgUrl: "./img/american-og.png",
    },
    {
      _id: "3",
      name: "Ngozi University",
      imgUrl: "./img/ngozi-univ.png",
    },
    {
      _id: "4",
      name: "Resolution Connect",
      imgUrl: "./img/resolution.png",
    },
    {
      _id: "5",
      name: "Irathos Kingdom Medical Clinic",
      imgUrl: "./img/IKMC.png",
    },
  ];

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <>
      {testimonials.length > 0 && (
        <>
          <div className="app__testimonial-item app__flex">
            <motion.div
              className="testimonial-img"
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.3 }}
              style={{ perspective: "1500px" }}
            >
              <motion.img
                src={testimonials[currentIndex].imgurl}
                alt={testimonials[currentIndex].name}
                whileHover={{ rotateY: 10 }}
                transition={{ duration: 0.3 }}
              />
            </motion.div>
            <div className="app__testimonial-content">
              <p className="p-text">{t(testimonials[currentIndex].feedback)}</p>
              <div>
                <h4 className="bold-text">{testimonials[currentIndex].name}</h4>
                <h5 className="p-text">
                  {t(testimonials[currentIndex].company)}
                </h5>
              </div>
            </div>
          </div>

          <div className="app__testimonial-btns app__flex">
            <motion.div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === 0
                    ? testimonials.length - 1
                    : currentIndex - 1
                )
              }
              whileHover={{ scale: 1.2 }}
              transition={{ duration: 0.3 }}
            >
              <HiChevronLeft />
            </motion.div>

            <motion.div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === testimonials.length - 1
                    ? 0
                    : currentIndex + 1
                )
              }
              whileHover={{ scale: 1.2 }}
              transition={{ duration: 0.3 }}
            >
              <HiChevronRight />
            </motion.div>
          </div>
        </>
      )}
      <br />
      <h2>{t("Trusted by")}</h2>
      <div className="app__testimonial-brands app__flex">
        {brands.map((brand) => (
          <motion.div
            whileInView={{ opacity: [0, 1], translateY: [-20, 0] }}
            transition={{ duration: 0.5, type: "tween" }}
            key={brand._id}
          >
            <img src={brand.imgUrl} alt={brand.name} />
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Testimonial, "app__testimonial"),
  "testimonial",
  "app__primarybg"
);

import React, { useState, useRef, useEffect } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { FaFlagUsa, FaCaretDown } from "react-icons/fa";
import { FiFlag } from "react-icons/fi";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { images } from "../../constants";
import "./Navbar.scss";

// Import flag images
import usaFlag from "./united-states-of-america-united-states-svgrepo-com.svg";
import franceFlag from "./france-svgrepo-com.svg";
import spainFlag from "./spain-svgrepo-com.svg";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const { t, i18n } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getLanguageFlag = () => {
    switch (i18n.language) {
      case "en":
        return <img src={usaFlag} alt="USA Flag" className="flag-icon" />;
      case "fr":
        return <img src={franceFlag} alt="France Flag" className="flag-icon" />;
      case "es":
        return <img src={spainFlag} alt="Spain Flag" className="flag-icon" />;
      default:
        return <FiFlag />;
    }
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.hyphenlogo} alt="logo" />
      </div>
      <ul className="app__navbar-links">
        {[
          "home",
          "service",
          "work",
          "skills",
          "team",
          "blog",
          "testimonial",
          "contact",
        ].map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{t(item)}</a>
          </li>
        ))}
      </ul>

      <div className="app__navbar-lang" ref={dropdownRef}>
        <div
          className="app__navbar-lang-selector"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          {getLanguageFlag()}
          <span>{i18n.language.toUpperCase()}</span>
          <FaCaretDown />
        </div>

        {isDropdownOpen && (
          <ul className="app__navbar-lang-dropdown">
            <li onClick={() => changeLanguage("en")}>
              <img src={usaFlag} alt="USA Flag" className="flag-icon" /> EN
            </li>
            <li onClick={() => changeLanguage("fr")}>
              <img src={franceFlag} alt="France Flag" className="flag-icon" />{" "}
              FR
            </li>
            <li onClick={() => changeLanguage("es")}>
              <img src={spainFlag} alt="Spain Flag" className="flag-icon" /> ES
            </li>
          </ul>
        )}
      </div>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {[
                "home",
                "service",
                "work",
                "skills",
                "team",
                "blog",
                "testimonial",
                "contact",
              ].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {t(item)}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

import React from "react";
import { useParams, Link } from "react-router-dom";
import BlogApi from "./BlogApi";
import "./BlogDetails.scss";
import { useTranslation } from "react-i18next";

const BlogDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const blog = BlogApi.find((blog) => blog.id.toString() === id);

  if (!blog) {
    return <p>Blog not found</p>;
  }

  return (
    <div className="blog-details-container">
      <button className="back-button">
        <Link to="/#blog">&larr; Back</Link>
      </button>
      <div className="blog-details">
        <div className="modal-blog">
          <div className="modal-content">
            <div className="modal-img">
              <img src={blog.image} alt="" />
            </div>
            <div className="modal-text">
              <span>{blog.date}</span>
              <h1>{t(blog.title_one)}</h1>
              <p>{t(blog.desc_one)}</p>

              <h1>{t(blog.title_two)}</h1>
              <p>{t(blog.desc_two)}</p>

              <h1>{t(blog.title_three)}</h1>
              <p>{t(blog.desc_three)}</p>

              {/* Leave a Reply Form */}
              {/* <div className="contact mtop">
                <h1>Leave a Reply</h1>
                <form className="blog_contact">
                  <div className="left">
                    <input type="text" placeholder="Name" />
                    <input type="email" placeholder="Email" />
                    <input type="text" placeholder="Website" />
                    <button className="btn_shadow">SUBMIT NOW</button>
                  </div>
                  <div className="right">
                    <textarea
                      cols="30"
                      rows="12"
                      placeholder="Comment"
                    ></textarea>
                  </div>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
